//mixins
@import "styles/mixins/fontface"

//fonts
@include fontFace('SamsungOne-200', '/assets/fonts/SamsungOne/SamsungOne-200')
@include fontFace('SamsungOne-300', '/assets/fonts/SamsungOne/SamsungOne-300')
@include fontFace('SamsungOne-400', '/assets/fonts/SamsungOne/SamsungOne-400')
@include fontFace('SamsungOne-450', '/assets/fonts/SamsungOne/SamsungOne-450')
@include fontFace('SamsungOne-500', '/assets/fonts/SamsungOne/SamsungOne-500')
@include fontFace('SamsungOne-600', '/assets/fonts/SamsungOne/SamsungOne-600')
@include fontFace('SamsungOne-700', '/assets/fonts/SamsungOne/SamsungOne-700')
@include fontFace('SamsungOne-800', '/assets/fonts/SamsungOne/SamsungOne-800')
@include fontFace('SamsungSharpSans-Regular', '/assets/fonts/SamsungSharpSans/SamsungSharpSans-Regular')
@include fontFace('SamsungSharpSans-Medium', '/assets/fonts/SamsungSharpSans/SamsungSharpSans-Medium')
@include fontFace('SamsungSharpSans-Bold', '/assets/fonts/SamsungSharpSans/SamsungSharpSans-Bold')

// sizes
$size8: 0.28333vw
$size16: 0.41667vw
$size24: 0.625vw
$size32: 0.83333vw
$size48: 1.25vw
$size56: 1.45833vw
$size64: 1.5625vw
$size72: 1.875vw
$size80: 2.08333vw
$size96: 2.5vw
$size104: 2.70833vw
$size120: 3.125vw

// theme
@import styles/prebootstrapvariables
@import ../node_modules/bootstrap/scss/functions
@import styles/bootstrapvariables
@import styles/variables

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap"
@import "styles/bootstrapoverwrites"

// stylesheets containers
@import styles/containers/Root
@import styles/containers/App
@import styles/containers/Landingpage
@import styles/containers/Dashboard
@import styles/containers/FourOFour

//stylesheets components

body
  margin: 0
  padding: 0
  overflow: scroll !important
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-size: cover
  background-repeat: no-repeat
  background-attachment: fixed
  background-position: top left

.TvWalletDashboard
  background-size: cover
  background-repeat: no-repeat
  background-attachment: fixed
  background-position: top left
  background-image: url(/assets/images/background2.jpg)

::-webkit-scrollbar
  display: none

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

.cursor-pointer
  cursor: pointer

.bigger-margin-top
  margin-top: 8rem
  @include media-breakpoint-down(md)
    margin-top: 3rem

.pointer-url
  cursor: pointer
  text-decoration: underline

.bigger-margin-bottom
  margin-bottom: 8rem
  @include media-breakpoint-down(md)
    margin-bottom: 3rem

.mobile-push-top
  margin-top: -4rem

.text-lg-prewrap
  @include media-breakpoint-up(lg)
    white-space: pre-wrap

.about-background
  background-position: left 34%
  background-size: contain
  background-repeat: no-repeat
  @include media-breakpoint-up(lg)
    background-position: left top

.navigationbar--sticky
  @include media-breakpoint-down(md)
    position: relative !important

.modal .btn-close
  filter: invert(1) grayscale(100%) brightness(50%)

.text-container
  padding: 20px /* Adjust padding as needed */

.image-container
  position: relative
  overflow: hidden

.image-overlay
  position: fixed
  top: 0
  bottom: 0
  left: 0
  height: 100%
  width: 100%
  background: linear-gradient(to left, rgba(22, 24, 26, 0), rgba(22, 24, 26, 0.85), rgba(22, 24, 26, 1), rgba(22, 24, 26, 1))
  z-index: 1

.carousel-caption
  z-index: 200

.contractcard, .tokencard
  &__body
    // height: #{$size96}
    padding: 0.25rem 0.25rem

.fs-16
  font-size: #{$size16}
.fs-24
  font-size: #{$size24}
.fs-32
  font-size: #{$size32}
.fs-48
  font-size: #{$size48}
.fs-56
  font-size: #{$size56}
.fs-64
  font-size: #{$size64}

.mt-24
  margin-top: #{$size24}

.mb-24
  margin-bottom: #{$size24}

.my-24
  margin-top: #{$size24}
  margin-bottom: #{$size24}

.ms-24
  margin-left: #{$size24}

.mx-24
  margin-left: #{$size24}
  margin-right: #{$size24}

.px-24
  padding-left: #{$size24}
  padding-right: #{$size24}

.mt-48
  margin-top: #{$size48}

.mb-48
  margin-bottom: #{$size48}

.my-48
  margin-top: #{$size48}
  margin-bottom: #{$size48}

.p-16
  padding: #{$size16}

.p-48
  padding: #{$size48}

.py-48
  padding-top: #{$size48}
  padding-bottom: #{$size48}

.py-120
  padding-top: #{$size120}
  padding-bottom: #{$size120}

.tv-slideshow
  --bs-body-font-size: #{$size32}
  font-size: var(--bs-body-font-size)
  h3
    font-size: #{$size48}

.tv-settings
  --bs-body-font-size: #{$size32}
  font-size: var(--bs-body-font-size)
  .btn-lg
    --bs-btn-font-size: #{$size56}
    --bs-btn-padding-x: #{$size16}
    --bs-btn-padding-y: #{$size8}
    &.rounded-pill
      --bs-btn-padding-x: #{$size64}

.tv-main-wrapper
  padding: #{$size80} #{$size32}
  --bs-body-font-size: #{$size32}
  font-size: var(--bs-body-font-size)
  .contractcard, .tokencard
    &__body
      height: #{$size104}
      padding: #{$size16} #{$size16}
      font-size: #{$size48}
  .tv-slideshow__caption
    h3
  .btn
  .btn-lg
    --bs-btn-font-size: #{$size56}
    --bs-btn-padding-x: #{$size16}
    --bs-btn-padding-y: #{$size8}
    &.rounded-pill
      --bs-btn-padding-x: #{$size64}

.selected-card
  transform: scale(1.05)
  z-index: 1

.selected-text
  font-size: #{$size64}

.container
  max-width: 100% !important

.token-not-visible
  opacity: 0.5

.card-selected
  opacity: 0.8

.token-visible
  opacity: 1

.react-code-input
  input
    padding-right: 0

.carousel-caption
  left: 5%
  text-align: left
  width: 40%
