.App

.App-logo
  animation: App-logo-spin infinite 20s linear
  height: 40vmin

.App-header
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: calc(10px + 2vmin)
  color: white

.App-link
  color: #61dafb

@keyframes App-logo-spin
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)
